#hero {
    background-color: #FFF;
    
    #heroContent {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        // position: relative;
        @include tablet {
            display: block;
        }
        @include mobile {
            display: block;
        }
        @include small-mobile {
            display: block;
        }
       
        .mainHeroContent {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            // background-color: #D14E1D;
        }
        &.orange {
            background: $orange;
            padding: 40px;
            h1 {
                font-size: 42px;
            }
        }
        &.bgImage {
            min-height: 560px;
            .mainHeroContent {
                min-height: 560px;
            }
            h1 {
                font-size: 42px;
                padding: 34px 0;
                margin: 0;
                @include mobile-full {
                    padding: 32px;
                    margin: 0;
                }
            }
        }
        &.withBlurb {
            //padding-bottom: 200px;
            //margin-bottom: 150px;
            @include tablet {
                margin-bottom: 0px;
            }
            @include mobile {
                margin-bottom: 0px;
            }
            @include small-mobile {
                margin-bottom:0px;
            }
        }
        h1 {
            color: #FFF;
        }
        .MuiGrid-spacing-xs-40-27{
            @include mobile-full{
                display: block;
            }
        }
    }
    .blurb {
        background: $orange;
        color: #FFFFFF;
        padding: 30px 60px 50px;
        @include mobile-full{
            padding: 0px;
        }
        p {
            font-size: 24px;
            margin: 0;
            line-height: 1.25em;
            margin: 0;
            a{
                color: #fff;
            }
        }
        @include mobile {
            bottom: 0;
            margin-top: 0;
            // margin-bottom: 40px;
            left: 0;
            position: relative;
            padding: 30px;
            width: auto;
            p {
                font-size: 25px;
            }
        }
    }
    /* Skeleton Loader styles */
    .skeleton-loader {
        background: #F1F1F1;
        width: 100%;
        height: 200px; /* or adjust based on the height of your header */
        animation: pulse 1.5s infinite ease-in-out;
    }
  
    @keyframes pulse {
        0% {
          background-color: #F1F1F1;
        }
        50% {
          background-color: #F1F1F1;
        }
        100% {
          background-color: #F1F1F1;
        }
    }
}
// Resources over-rides
.resourceContainer {
    #hero {
        #heroContent {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            @include tablet {
                display: block;
            }
            @include mobile {
                display: block;
                
            }
            @include small-mobile {
                display: block;
            }
            &.orange {
                background: $orange;
                padding: 40px;
                h1 {
                    font-size: 42px;
                }
                @include mobile-full{
                    padding-left: 32px;
                    padding-right: 32px;
                }
            }
            margin-bottom: 200px;
            &.bgImage {
                h1 {
                    font-size: 31.25px;
                }
            }
            @include mobile-full {
                margin-bottom: 210px;
            }
        }
        .blurb {
            background: $orange;
            color: #FFFFFF;
            padding: 30px 60px 30px;
            position: absolute;
            left: 10%;
            bottom: -40%;
            margin-top: -200px;
            text-align: left;
            width: 520px;
            @include mobile-full {
                left: 0;
                right: 0;
                width: auto;
                padding: 30px 54px 37px 32px;
                bottom: -32% !important;
                margin: 0;
                min-height: 110px;
                p {
                    font-size: 25px;
                }
            }

            &.relative {
                position: relative;
                margin-top: 0;
                width: auto;
                left: 0;
            }
        }
    }
}

.resourceContainer {
    #hero {
        #heroContent {
            &.bgImage {
                h1 {
                    max-width: 100%;
                }
            }
        }
        .blurb {
            bottom: -10%;
            h1 {
                width: 100%;
            }
        }
    }
}