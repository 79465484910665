#homeRedesign2023-header-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100vw;
    left: 0;
    top: 0;
}

/* Skeleton Loader */
.skeleton-loader {
    width: 62vw; /* Same width as the image container */
    height: 500px; /* Match the height of the hero image container */
    background: #e0e0e0;
    animation: pulse 1.5s infinite ease-in-out;
}

/* Animation for the skeleton loader */
@keyframes pulse {
    0% { background-color: #e0e0e0; }
    50% { background-color: #f0f0f0; }
    100% { background-color: #e0e0e0; }
}

/* Hero Image Container */
.homeRedesign2023-header-image-container {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center; /* Center the image */
    background-size: cover; /* Stretch and zoom the image */
    background-repeat: no-repeat; /* Prevent repeating the image */
    width: 62vw; /* Default width, can change for smaller screens */
    height: 500px; /* Fixed height */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    overflow: hidden; /* Hide any overflow */
}

/* Show Image When Loaded */
.homeRedesign2023-header-image-container.visible {
    opacity: 1;
}

/* Hide Skeleton When Image Loads */
.homeRedesign2023-header-image-container.visible + .skeleton-loader {
    display: none;
}

/* Apply full width to the image */
.homeRedesign2023-header-image-container img {
    width: 100vw; /* Full width for the image */
    object-fit: cover; /* Ensures the image is zoomed and cropped */
    height: 100%; /* Ensure the image takes full height of the container */
}

/* Text Container */
.homeRedesign2023-header-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 38vw;
    background-color: #d14e1d;
    padding: 50px;
}

.homeRedesign2023-header-text-container > h1 {
    font-size: 35px;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: white;
    text-align: left;
}

.homeRedesign2023-header-emphasized-text {
    font-family: 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.homeRedesign2023-header-text-container > a {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: x-large;
    color: white;
    text-align: left;
    text-decoration: none;
}

/* Responsive Styles */
@media only screen and (max-width: 1150px) {
    #homeRedesign2023-header-container {
        flex-direction: column;
        height: 750px;
        align-items: center;
    }

    /* Stretch hero image to full width for all viewports under 1150px */
    .homeRedesign2023-header-image-container {
        height: 60%;
        width: 100vw; /* Stretch the image to full width of the viewport */
        max-width: 100vw; /* Remove any max-width restrictions */
    }

    /* Ensure skeleton loader stretches to full width */
    .skeleton-loader {
        height: 60%;
        width: 100vw;
    }

    .homeRedesign2023-header-text-container {
        width: 100%;
        max-width: 100vw;
        height: 40%;
        box-sizing: border-box;
    }

    .homeRedesign2023-header-text-container > h1 {
        font-size: 30px;
    }

    .homeRedesign2023-header-text-container > a {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 700px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 500px) {
    #homeRedesign2023-header-container {
        height: 500px;
    }
    .homeRedesign2023-header-image-container {
        height: 50%;
    }
    .skeleton-loader {
        height: 50%;
    }
    .homeRedesign2023-header-text-container {
        height: 50%;
        padding: 50px;
        background-color: #BE5127;
    }
    .homeRedesign2023-header-text-container > h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 376px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 350px) {
    .homeRedesign2023-header-text-container > h1 {
        font-size: 16px;
    }
}
